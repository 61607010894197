import React from 'react';
import { Box } from '@material-ui/core';
import { element, string } from 'prop-types';
import TypographyWrapper, {
  TypographyFormat,
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import { defaultTreetStyles } from '../../shopConfig/config';
import { useShopConfig } from '../../hooks/shopConfig';
import SectionQuirksAndNotes from './SectionQuirksAndNotes';
import { IconRuler } from '../../components';
import { useCurrentListing } from './hooks/useCurrentListing';
import { useVariantDetails } from './hooks/useVariantDetails';
import css from './ListingPage.module.css';

const SectionHeading = (props) => {
  const { priceTitle, formattedPrice, formattedOriginalPrice, richTitle } = props;

  const variantDetails = useVariantDetails();

  const { currentListing: listing } = useCurrentListing();
  const { sizeVariantOptionName, sizeGuideUrl, sizeGuideLabel } = useShopConfig();

  const renderVariantOptions = () =>
    Object.entries(variantDetails).map(([variantOptionName, variantOptionValue]) => (
      <Box
        key={`${variantOptionName}-${variantOptionValue}`}
        mb={2}
        display="flex"
        justifyContent="space-between"
      >
        <Box>
          <TypographyWrapper
            variant="body1"
            weight={TypographyWeight.Bold}
            typographyOverrides={{ display: 'inline' }}
          >
            {variantOptionName}:
          </TypographyWrapper>{' '}
          <TypographyWrapper
            variant="body1"
            typographyOverrides={{ display: 'inline' }}
            isDynamicValue
          >
            {variantOptionValue}
          </TypographyWrapper>
        </Box>
        {variantOptionName.toLowerCase() === sizeVariantOptionName && (
          <Box display="flex" alignItems="center">
            {sizeGuideUrl && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={sizeGuideUrl}
                className={css.sizeGuideLink}
              >
                <IconRuler className={css.sizeGuideRulerIcon} />
                <TypographyWrapper
                  variant="body1"
                  format={TypographyFormat.Underlined}
                  typographyOverrides={{
                    display: 'inline',
                  }}
                >
                  {sizeGuideLabel}
                </TypographyWrapper>
              </a>
            )}
          </Box>
        )}
      </Box>
    ));

  return (
    <div className={css.sectionHeading}>
      <div className={css.infoPanel}>
        <h1 className={css.title}>
          <TypographyWrapper variant="h1" isDynamicValue>
            {richTitle}
          </TypographyWrapper>
        </h1>
        <Box mb={1} className={css.desktopPriceContainer}>
          {formattedOriginalPrice && (
            <div
              className={css.desktopOriginalPriceValue}
              title={`Original MSRP: ${formattedOriginalPrice}`}
            >
              <TypographyWrapper
                variant="h2"
                typographyOverrides={{ style: { textDecoration: 'line-through' } }}
                isDynamicValue
              >
                {formattedOriginalPrice}
              </TypographyWrapper>
            </div>
          )}
          <div className={css.desktopPriceValue} title={`Listed Price: ${priceTitle}`}>
            <TypographyWrapper
              variant="h2"
              typographyOverrides={{ style: { color: defaultTreetStyles.red60 } }}
              isDynamicValue
            >
              {formattedPrice}
            </TypographyWrapper>
          </div>
        </Box>
        {renderVariantOptions()}
        <SectionQuirksAndNotes listing={listing} />
      </div>
    </div>
  );
};

SectionHeading.propTypes = {
  priceTitle: string,
  formattedPrice: string,
  formattedOriginalPrice: string,
  richTitle: element,
};

export default SectionHeading;

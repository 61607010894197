/* eslint-disable import/prefer-default-export */
import { useContext } from 'react';
import AppContext from '../context/AppContext';
import { useLoyaltyProgramByTreetId } from '../types/apollo/generated/types.generated';
import { useShopConfig } from './shopConfig';
import { ShopLoyaltyProgram } from '../types/loyalty';

export const useShopLoyaltyProgram = (): ShopLoyaltyProgram | null => {
  const { treetId } = useContext(AppContext);
  const { loyaltyPointsConfig: loyaltyPointsConfigFromShopConfig } = useShopConfig();
  const { data } = useLoyaltyProgramByTreetId({
    variables: {
      treetId,
    },
  });
  const loyaltyPointsConfigFromGraphQL = data?.shop?.loyaltyProgram ?? null;
  if (loyaltyPointsConfigFromGraphQL) {
    return {
      ...loyaltyPointsConfigFromShopConfig,
      ...loyaltyPointsConfigFromGraphQL,
    };
  }
  return null;
};

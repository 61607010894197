/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Chip, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { string } from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Magnifier } from 'react-image-magnifiers';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { Builder } from '@builder.io/react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useShopConfig } from '../../hooks/shopConfig';
import ActionBarMaybe from './ActionBarMaybe';
import {
  defaultTreetStyles,
  FEATURED_LISTING_IMAGE_TYPE,
  FEATURED_LISTING_IMAGE_TYPE_TAB_NAMES,
} from '../../shopConfig/config';
import {
  getLargeUploadcareImagePreviewUrl,
  getUploadcareImagePreviewUrl,
  ImageSource,
} from '../../util/uploadcare';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { getStockImagesForListing } from '../../util/listings/listingImages';
import { useIsMobile } from '../../hooks/useIsMobile';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { AvatarSmall } from '../../components/Avatar/Avatar';
import { IconUserFilled, InlineTextButton, TypographyWrapper } from '../../components';
import {
  TypographyFormat,
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import AppContext from '../../context/AppContext';
import { trackZoomPDPImage } from '../../util/heap';
import { types as sdkTypes } from '../../util/sdkLoader';
import ActionBarMaybeDesktop from './ActionBarMaybeDesktop';
import {
  createSlug,
  LISTING_PAGE_DRAFT_VARIANT,
  ListingPageParamType,
} from '../../util/urlHelpers';
import { useCurrentListing } from './hooks/useCurrentListing';
import { BackgroundBlurBox } from '../../components/BackgroundBlurBox/BackgroundBlurBox';
import { shouldRenderBackgroundBlurFn } from './SectionImages.utils';
import { setHasFirstMediaSrcLoaded } from './ListingPage.duck';
import css from './ListingPage.module.css';
import './ListingPageSwiper.css';

const { UUID } = sdkTypes;

const FALLBACK_IMAGE_RATIO_FOR_VERTICAL_IMAGES = 1;
const CAROUSEL_ELEMENT_IDENTIFIER = '.carouselImagesWrapper';
const MEDIA_QUERY_LARGE_CAROUSEL_THUMBNAIL_WIDTH = '120px';
// Keep in sync with marketplace.css
const TOPBAR_HEIGHT_DESKTOP = 84;
const MIN_FULL_SCREEN_CAROUSEL_HEIGHT = 700;

const MEDIA_TYPE = {
  VIDEO: 'video',
  IMAGE: 'img',
};

const useImageChipStyles = makeStyles({
  root: {
    backgroundColor: (props) => props.backgroundColor,
    color: 'white',
    height: '42px',
    fontSize: '16px',
  },
});

const SWIPER_ARROW_STYLE = {
  color: defaultTreetStyles.gray60,
  backgroundColor: '#fff',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
};

const ImageChip = (props) => {
  const { backgroundColor, ...rest } = props;
  const classes = useImageChipStyles(props);
  return <Chip classes={classes} {...rest} />;
};

ImageChip.defaultProps = { backgroundColor: null };
ImageChip.propTypes = { backgroundColor: string };

const MagnifyImage = (props) => {
  const { imageSrc, largeImageSrc, imageAlt, listingId, onLoad, onError } = props;

  const { treetId } = useContext(AppContext);

  const handleZoomStart = () => trackZoomPDPImage(listingId, imageSrc, treetId);

  return imageSrc ? (
    <Magnifier
      // Can't use CSS Modules to target Magnifier divs
      className="magnifier"
      imageSrc={imageSrc}
      largeImageSrc={largeImageSrc}
      imageAlt={imageAlt}
      dragToMove={false}
      onZoomStart={handleZoomStart}
      onLoad={onLoad}
      onError={onError}
    />
  ) : (
    <img src={imageSrc} alt={imageAlt} onLoad={onLoad} onError={onError} />
  );
};

const StockOrSellerChip = (props) => {
  const { isStock, isVideo, user } = props;

  const { css: shopCss } = useShopConfig();
  const { allowSell } = useEnabledCustomerExperiences();

  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const chipTitle = isStock ? (isVideo ? 'Stock Video' : 'Stock Image') : 'Seller Image';

  let backgroundColor = shopCss?.productBanner?.backgroundColor || defaultTreetStyles.gray80;
  let fontColor = shopCss?.productBanner?.fontColor;

  if (!allowSell) return null;

  backgroundColor = isStock ? defaultTreetStyles.gray00 : defaultTreetStyles.gray100;
  fontColor = isStock ? defaultTreetStyles.gray80 : fontColor || defaultTreetStyles.gray10;

  return (
    <ImageChip
      backgroundColor={backgroundColor}
      className={css.imageChipV2}
      style={{ borderRadius: '30px', padding: isStock ? undefined : '0 4px' }}
      avatar={
        isStock ? undefined : (
          <AvatarSmall
            user={ensuredUser}
            disableProfileLink
            className={css.imageChipAvatar}
            noProfileImageIcon={
              <IconUserFilled
                color={defaultTreetStyles.gray10}
                className={css.imageChipNoProfileImageIcon}
              />
            }
          />
        )
      }
      label={
        <TypographyWrapper variant="body1" typographyOverrides={{ style: { color: fontColor } }}>
          {chipTitle}
        </TypographyWrapper>
      }
    />
  );
};

const Tab = (props) => {
  const { handleClick, isActive, tabName } = props;

  const fontColor = isActive ? defaultTreetStyles.gray100 : defaultTreetStyles.gray60;

  return (
    <InlineTextButton
      onClick={handleClick}
      typographyVariant="body2"
      typographyFormat={isActive ? TypographyFormat.Underlined : undefined}
      typographyWeight={TypographyWeight.Bold}
      style={{ paddingTop: 0, paddingBottom: 0 }}
      typographyOverrides={{
        style: {
          lineHeight: '16px',
          fontSize: '13px',
          color: fontColor,
          paddingLeft: '4px',
          paddingRight: '4px',
          textDecorationColor: fontColor,
        },
      }}
    >
      {tabName}
    </InlineTextButton>
  );
};

const SwiperCarousel = (props) => {
  const {
    carouselMedia,
    thumbnailImages,
    numStockMedia,
    numSellerMedia,
    swiperWrapperRef,
    isOwnListing,
    listing,
    editParams,
  } = props;

  const {
    listingFlowConfig: { featuredListingImageType },
  } = useShopConfig();
  const carouselSwiperRef = useRef(null);
  const firstCarouselSlideRef = useRef(null);

  const isMobile = useIsMobile();
  const theme = useTheme();
  const isMediaQueryLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [swiperLibrary, setSwiperLibrary] = useState(undefined);
  const [swiperReactLibrary, setSwiperReactLibrary] = useState(undefined);
  const [activeTab, setActiveTab] = useState(featuredListingImageType);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      // Load this in asynchronously
      // because for some reason, it runs into an error with SSR
      const SwiperCore = await import('swiper');
      const SwiperReact = await import('swiper/react');
      const SwiperStyles = await import('./swiperStylesUtils');
      await SwiperStyles.default();
      setSwiperLibrary(SwiperCore);
      setSwiperReactLibrary(SwiperReact);
    };

    loadData();
  }, []);

  const firstTab = featuredListingImageType;
  const secondTab = Object.keys(FEATURED_LISTING_IMAGE_TYPE).find((type) => type !== firstTab);
  const secondTabFirstImageIndex =
    featuredListingImageType === FEATURED_LISTING_IMAGE_TYPE.STOCK ? numStockMedia : numSellerMedia;

  const toggleActiveTab = (tab) => {
    setActiveTab(tab);
    if (carouselSwiperRef?.current && carouselSwiperRef.current.swiper) {
      const { swiper } = carouselSwiperRef.current;
      const indexToScroll = tab === firstTab ? 0 : secondTabFirstImageIndex;
      swiper.slideTo(indexToScroll);
    }
  };

  const handleSlideChange = debounce((swiper) => {
    const imageIndex = swiper.realIndex;
    const isIndexOverSecondTabFirstImageIndex = imageIndex >= secondTabFirstImageIndex;
    setActiveSlideIndex(imageIndex);
    setActiveTab(isIndexOverSecondTabFirstImageIndex ? secondTab : firstTab);
  }, 200);

  if (!swiperLibrary || !swiperReactLibrary) return null;

  const hasSellerAndStockImages = numStockMedia > 0 && numSellerMedia > 0;

  const { Swiper, SwiperSlide } = swiperReactLibrary;
  const { Navigation, Mousewheel, Thumbs } = swiperLibrary;

  return (
    <Box ref={swiperWrapperRef} display="flex" flexDirection={{ xs: 'column-reverse', lg: 'row' }}>
      <Box
        maxHeight="100%"
        width={{ xs: '85%', lg: MEDIA_QUERY_LARGE_CAROUSEL_THUMBNAIL_WIDTH }}
        margin={{ xs: '24px auto 0', lg: '0 12px 0 0' }}
      >
        {hasSellerAndStockImages && (
          <Box display="flex" justifyContent="center" mb={2}>
            <Tab
              handleClick={() => toggleActiveTab(firstTab)}
              isActive={activeTab === firstTab}
              tabName={FEATURED_LISTING_IMAGE_TYPE_TAB_NAMES[firstTab]}
            />
            <Tab
              handleClick={() => toggleActiveTab(secondTab)}
              isActive={activeTab === secondTab}
              tabName={FEATURED_LISTING_IMAGE_TYPE_TAB_NAMES[secondTab]}
            />
          </Box>
        )}
        <Swiper
          onSwiper={setThumbsSwiper}
          modules={[Mousewheel]}
          mousewheel
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          className={classNames(`${css.thumbnailsSwiper} thumbs-wrapper`, {
            [css.thumbnailsSwiperWithTabs]: hasSellerAndStockImages,
          })}
          // cssMode enabled prevents lag on mobile when swiping
          cssMode
          breakpoints={{
            0: {
              direction: 'horizontal',
              slidesPerView: 5,
              spaceBetween: 10,
            },
            1024: {
              direction: 'vertical',
              slidesPerView: 'auto',
              spaceBetween: 10,
            },
          }}
        >
          {thumbnailImages.map((image) => (
            <SwiperSlide key={image.key}>{image}</SwiperSlide>
          ))}
        </Swiper>
      </Box>

      <Box
        maxHeight="100%"
        width={{ xs: '100%', lg: `calc(100% - ${MEDIA_QUERY_LARGE_CAROUSEL_THUMBNAIL_WIDTH})` }}
      >
        <Swiper
          ref={carouselSwiperRef}
          thumbs={{ swiper: thumbsSwiper }}
          onSlideChange={handleSlideChange}
          slidesPerView={1}
          // cssMode enabled prevents lag on mobile when swiping
          cssMode
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          allowTouchMove={isMobile}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          className={`${css.carouselMediaSwiper} carouselImagesWrapper`}
          modules={[Navigation, Thumbs]}
        >
          {carouselMedia.map((media) => {
            const isVideo = media.props.type === MEDIA_TYPE.VIDEO;

            const shouldRenderBackgroundBlur = shouldRenderBackgroundBlurFn(
              media.key,
              listing.attributes.publicData?.images
            );

            return (
              <SwiperSlide ref={firstCarouselSlideRef} key={media.key}>
                {isMediaQueryLarge && (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    position="absolute"
                    top={0}
                    width="100%"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Box width="100%" position="relative">
                      <ActionBarMaybeDesktop
                        isOwnListing={isOwnListing}
                        listing={listing}
                        editParams={editParams}
                      />
                    </Box>
                  </Box>
                )}
                {isVideo ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{ backgroundColor: 'black' }}
                    height={{ md: '100%' }}
                  >
                    <Box maxHeight={{ xs: '400px', md: '100%' }} maxWidth="100%">
                      {media}
                    </Box>
                  </Box>
                ) : shouldRenderBackgroundBlur ? (
                  <BackgroundBlurBox
                    imageSrc={media.key}
                    keyToUse={`${media.key}-${activeSlideIndex}`}
                  >
                    {media}
                  </BackgroundBlurBox>
                ) : (
                  media
                )}
              </SwiperSlide>
            );
          })}
          <div className="swiper-button-next" style={SWIPER_ARROW_STYLE}>
            <ChevronRightIcon />
          </div>
          <div className="swiper-button-prev" style={SWIPER_ARROW_STYLE}>
            <ChevronLeftIcon />
          </div>
        </Swiper>
      </Box>
    </Box>
  );
};

const SectionImages = () => {
  const {
    maxStockImages,
    chooseStockImagesOption,
    listingFlowConfig: { featuredListingImageType },
    imageRatio,
  } = useShopConfig();

  const params = useParams();
  const swiperWrapperRef = useRef(null);
  const isMobile = useIsMobile();
  const { currentListing, isOwnListing } = useCurrentListing();
  const currentListingId = currentListing.id.uuid;

  const dispatch = useDispatch();
  const setHasLoadedFirstMediaSrc = () => dispatch(setHasFirstMediaSrcLoaded());

  const listingId = new UUID(params.id);
  const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;

  const {
    imageSource,
    images: uploadcareImages,
    stockVideoUrls = [],
  } = currentListing.attributes.publicData || {};
  const isUploadcareEnabled = imageSource === ImageSource.Uploadcare;

  const theme = useTheme();
  const isMediaQueryLarge = useMediaQuery(theme.breakpoints.up('lg'));

  const [erroredMediaSrcs, setErroredMediaSrcs] = useState([]);

  const dynamicallyScaleCarouselSize = () => {
    if (isMobile) return;
    // Based on the image ratio, we want to fix the height of the carousel so we don't
    // resize all the images based on the largest one
    const sliderWrapper = document.querySelector(CAROUSEL_ELEMENT_IDENTIFIER);
    if (!sliderWrapper) return;

    // For brands with a low image ratio, we want to make it larger to accommodate for seller
    // images that are usually taken on a phone and thus have a vertical aspect ratio. We do
    // this across every listing so that the images do not jump around while toggling listings
    const displayRatio = imageRatio <= 1 ? FALLBACK_IMAGE_RATIO_FOR_VERTICAL_IMAGES : imageRatio;
    const carouselWidth = sliderWrapper.clientWidth;
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const vhBasedHeight = vh - TOPBAR_HEIGHT_DESKTOP - 120; // extra padding for bottom
    const windowBasedHeight = Math.max(vhBasedHeight, MIN_FULL_SCREEN_CAROUSEL_HEIGHT);
    const heightToUse = `${Math.min(
      Math.round(carouselWidth * displayRatio),
      windowBasedHeight
    )}px`;

    if (swiperWrapperRef.current && isMediaQueryLarge) {
      swiperWrapperRef.current.style.height = heightToUse;
    }
    sliderWrapper.style.height = heightToUse;
  };

  useEffect(() => {
    dynamicallyScaleCarouselSize();
  }, [currentListing]);

  const stockImages =
    maxStockImages > 0 ? getStockImagesForListing(currentListing, chooseStockImagesOption) : [];
  const validStockImages = stockImages
    .filter((src) => !erroredMediaSrcs.includes(src))
    .slice(0, maxStockImages);
  const validStockVideos = stockVideoUrls.filter((src) => !erroredMediaSrcs.includes(src));
  const validSellerImages = isUploadcareEnabled ? uploadcareImages : currentListing.images;

  const numStockMedia = validStockImages.length + validStockVideos.length;
  const numSellerMedia = validSellerImages.length;

  useEffect(() => {
    // If there are no stock or seller images, set the first media src as loaded
    // so that we display the details section.
    if (numStockMedia === 0 && numSellerMedia === 0) {
      setHasLoadedFirstMediaSrc();
    }
  }, [numStockMedia, numSellerMedia]);

  const getStockImageEls = (shouldMagnify, isFeatured) =>
    validStockImages.map((src, index) => {
      const imageUrl = isMobile
        ? getUploadcareImagePreviewUrl(src)
        : getLargeUploadcareImagePreviewUrl(src);

      const setHasLoadedFirstMediaSrcFn =
        isFeatured && index === 0 ? setHasLoadedFirstMediaSrc : undefined;

      return shouldMagnify && !isMobile ? (
        <MagnifyImage
          key={imageUrl}
          imageSrc={imageUrl}
          imageAlt="product stock photo"
          listingId={currentListingId}
          onLoad={setHasLoadedFirstMediaSrcFn}
          onError={setHasLoadedFirstMediaSrcFn}
        />
      ) : (
        <img
          className={css.swiperCarouselImage}
          src={imageUrl}
          key={imageUrl}
          alt="product stock photo"
          onLoad={setHasLoadedFirstMediaSrcFn}
          onError={() => {
            setErroredMediaSrcs([...erroredMediaSrcs, src]);
            if (setHasLoadedFirstMediaSrcFn) setHasLoadedFirstMediaSrcFn();
          }}
        />
      );
    });

  const getStockVideoEls = (shouldAutoplayVideo, isFeatured) =>
    validStockVideos.map((src, index) => {
      const setHasLoadedFirstMediaSrcFn =
        isFeatured && index === 0 ? setHasLoadedFirstMediaSrc : undefined;

      return (
        <video
          src={src}
          key={src}
          autoPlay={shouldAutoplayVideo && !isMobile}
          controls={isMobile}
          playsInline
          muted
          loop
          className={classNames(css.sectionMediaVideo, css.swiperCarouselImage)}
          width="100%"
          onError={() => {
            setErroredMediaSrcs([...erroredMediaSrcs, src]);
            if (setHasLoadedFirstMediaSrcFn) setHasLoadedFirstMediaSrcFn();
          }}
          onLoadedData={setHasLoadedFirstMediaSrcFn}
        />
      );
    });

  const getSellerImageEls = (shouldMagnify, isFeatured) => {
    const sellerImageEls = [];
    if (isUploadcareEnabled) {
      uploadcareImages?.forEach((image, index) => {
        const imageUrl = isMobile
          ? getUploadcareImagePreviewUrl(image.cdnUrl)
          : getLargeUploadcareImagePreviewUrl(image.cdnUrl);

        const setHasLoadedFirstMediaSrcFn =
          isFeatured && index === 0 ? setHasLoadedFirstMediaSrc : undefined;

        const sellerImage =
          shouldMagnify && !isMobile ? (
            <MagnifyImage
              key={imageUrl}
              imageSrc={imageUrl}
              imageAlt="seller uploaded"
              listingId={currentListingId}
              onLoad={setHasLoadedFirstMediaSrcFn}
              onError={setHasLoadedFirstMediaSrcFn}
            />
          ) : (
            <img
              className={css.swiperCarouselImage}
              key={imageUrl}
              src={imageUrl}
              alt="seller uploaded"
              onLoad={setHasLoadedFirstMediaSrcFn}
              onError={setHasLoadedFirstMediaSrcFn}
            />
          );
        sellerImageEls.push(sellerImage);
      });
    } else {
      currentListing.images.forEach((image, index) => {
        const imageUrl =
          image.attributes.variants['scaled-medium']?.url ||
          image.attributes.variants.landscape?.url ||
          image.attributes.variants.default?.url;

        const setHasLoadedFirstMediaSrcFn =
          isFeatured && index === 0 ? setHasLoadedFirstMediaSrc : undefined;

        const sellerImage =
          shouldMagnify && !isMobile ? (
            <MagnifyImage
              key={imageUrl}
              imageSrc={imageUrl}
              imageAlt="seller uploaded"
              listingId={currentListingId}
              onLoad={setHasLoadedFirstMediaSrcFn}
              onError={setHasLoadedFirstMediaSrcFn}
            />
          ) : (
            <img
              src={imageUrl}
              key={imageUrl}
              alt="seller uploaded"
              className={css.swiperCarouselImage}
              onLoad={setHasLoadedFirstMediaSrcFn}
              onError={setHasLoadedFirstMediaSrcFn}
            />
          );
        sellerImageEls.push(sellerImage);
      });
    }
    return sellerImageEls;
  };

  const renderCarouselMedia = (carouselProps) => {
    const { shouldDisplayChip, shouldAutoplayVideo, shouldMagnify } = carouselProps || {};

    const areSellerImagesFeatured =
      featuredListingImageType === FEATURED_LISTING_IMAGE_TYPE.USER_UPLOADED;

    const isStockMediaFeatured =
      !areSellerImagesFeatured ||
      // Case where seller images are featured but there are no seller images
      (isUploadcareEnabled && (!uploadcareImages || uploadcareImages.length === 0)) ||
      (!isUploadcareEnabled && currentListing.images.length === 0);
    const numVideoEls = validStockVideos.length;

    const isStockImageFeatured = isStockMediaFeatured && numVideoEls === 0;
    const stockImageEls = getStockImageEls(shouldMagnify, isStockImageFeatured);

    const isStockVideoFeatured = isStockMediaFeatured && numVideoEls > 0;
    const stockVideoEls = getStockVideoEls(shouldAutoplayVideo, isStockVideoFeatured);

    const isSellerImageFeatured =
      !isStockMediaFeatured || (stockImageEls.length === 0 && stockVideoEls.length === 0);
    const sellerImageEls = getSellerImageEls(shouldMagnify, isSellerImageFeatured);

    let allMediaEls = [];
    if (areSellerImagesFeatured) {
      allMediaEls = [...sellerImageEls, ...stockImageEls, ...stockVideoEls];
    } else {
      allMediaEls = [...stockImageEls, ...stockVideoEls, ...sellerImageEls];
    }

    if (!shouldDisplayChip) return allMediaEls;

    // Add a chip to each image/video to denote if it is a seller or stock image/video
    return allMediaEls.map((mediaEl, index) => {
      const isStock = areSellerImagesFeatured
        ? index >= allMediaEls.length - numStockMedia
        : index < numStockMedia;
      const isVideo = mediaEl.type === MEDIA_TYPE.VIDEO;

      return (
        <Box display="flex" justifyContent="center" height="100%" key={mediaEl.key || index}>
          <StockOrSellerChip isStock={isStock} isVideo={isVideo} user={currentListing.author} />
          {mediaEl}
        </Box>
      );
    });
  };

  const listingSlug = params.slug || createSlug(currentListing.attributes.title || '');
  const listingType = isDraftVariant ? ListingPageParamType.Draft : ListingPageParamType.Edit;
  // Default to whatever page the user is on if it's a draft, otherwise go to the pricing page
  // (most commonly edited page).
  // NOTE: if the default draft tab ever changes, make sure to update abandonedListingEmailJob also.
  const listingTab = isDraftVariant ? 'shipping' : 'pricing';
  const editParams = {
    id: listingId.uuid,
    slug: listingSlug,
    type: listingType,
    tab: listingTab,
  };

  return (
    <Box mt={{ xs: 0, md: 3 }}>
      {!isMediaQueryLarge && (
        <Box onClick={(e) => e.stopPropagation()}>
          <Box width="100%">
            <ActionBarMaybe
              isOwnListing={isOwnListing}
              listing={currentListing}
              editParams={editParams}
            />
          </Box>
        </Box>
      )}
      <SwiperCarousel
        key={currentListingId}
        carouselMedia={renderCarouselMedia({
          shouldDisplayChip: true,
          shouldAutoplayVideo: true,
          shouldMagnify: true,
        })}
        thumbnailImages={renderCarouselMedia()}
        numStockMedia={numStockMedia}
        numSellerMedia={numSellerMedia}
        swiperWrapperRef={swiperWrapperRef}
        isOwnListing={isOwnListing}
        listing={currentListing}
        editParams={editParams}
      />
    </Box>
  );
};

Builder.registerComponent(SectionImages, {
  name: 'SectionImages',
});

export default SectionImages;

import Box from '@material-ui/core/Box';
import React from 'react';
import TypographyWrapper, {
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import { propTypes } from '../../util/types';
import MarkdownText from '../../components/MarkdownText/MarkdownText';
import { useSellerNotes } from './hooks/useSellerNotes';

const SectionBox = ({ children }) => <Box mb={2}>{children}</Box>;

const SectionQuirksAndNotes = (props) => {
  const { listing } = props;
  const { hasNoteSuffix, notes } = useSellerNotes(listing);

  const { publicData } = listing.attributes;
  const { conditionInfo } = publicData;
  const { quirks } = conditionInfo || {};

  function renderQuirks() {
    return quirks.join(', ');
  }

  return (
    <Box>
      {quirks?.length > 0 && (
        <SectionBox>
          <TypographyWrapper
            variant="body1"
            weight={TypographyWeight.Bold}
            typographyOverrides={{ display: 'inline' }}
          >
            Quirks:
          </TypographyWrapper>{' '}
          <TypographyWrapper
            variant="body1"
            typographyOverrides={{ display: 'inline' }}
            isDynamicValue
          >
            {renderQuirks()}
          </TypographyWrapper>
        </SectionBox>
      )}
      {notes && (
        <SectionBox>
          <TypographyWrapper
            variant="body1"
            weight={TypographyWeight.Bold}
            typographyOverrides={{ display: 'inline' }}
          >
            Seller Notes:
          </TypographyWrapper>{' '}
          <TypographyWrapper
            variant="body1"
            typographyOverrides={{ display: 'inline', style: { whiteSpace: 'pre-wrap' } }}
            isDynamicValue
          >
            {hasNoteSuffix ? (
              <MarkdownText markdownText={notes} typographyOverrides={{ display: 'inline' }} />
            ) : (
              notes
            )}
          </TypographyWrapper>
        </SectionBox>
      )}
    </Box>
  );
};

SectionQuirksAndNotes.propTypes = {
  listing: propTypes.listing.isRequired,
};

export default SectionQuirksAndNotes;
